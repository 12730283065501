<template>
    <div>
       <hero></hero>
        <v-user-manual-details></v-user-manual-details>
       <l-footer></l-footer>
    </div>
</template>

<script>
    export default {
        name: "UserManualDetails"
    }
</script>

<style scoped>

</style>
